import React from "react"
import loadable from '@loadable/component'
import CustomPlaceholder from './CustomPlaceholder'
const InnerExpressionTreeViewer = loadable(() => import("./InnerExpressionTreeViewer"), {fallback: <CustomPlaceholder/>})

function ExpressionTreeViewer({...props}) {
    return(
        <div>
            <InnerExpressionTreeViewer {...props} />
        </div>
    );
}

export default ExpressionTreeViewer;