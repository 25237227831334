import React from "react"
import {
  Container,
  Header,
  Icon,
  Breadcrumb,
  Message,
  List,
} from "semantic-ui-react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/progmiscon-logo-64.png"

import ExpressionTreeViewer from "../components/ExpressionTreeViewer"
import Image2 from "../components/image2"
const shortcodes = { ExpressionTreeViewer }

const NotionalMachinePageTemplate = ({ data }) => {
  const node = data.nm;
  const mcs = data.mcs;
  return (
    <Layout>
      <SEO title={node.frontmatter.id} />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/notionalMachines">
            Notional Machines
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{node.frontmatter.name}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="settings" />
          <Header.Content>
            {node.frontmatter.name}
            <Header.Subheader>
              {node.frontmatter.shortDescription}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Image2
          className="ui medium right floated image"
          src={`nm-${node.frontmatter.id}.jpg`}
        />
        
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{node.body}</MDXRenderer>
        </MDXProvider>

        {
          node.frontmatter.url
          ?
            <Message icon info>
              <Icon name="info circle" />
              <Message.Content>
                <Message.Header>Notional Machine Collection</Message.Header>
                <p>
                  The <a href="https://notionalmachines.github.io/">notionalmachines.github.io</a> web site
                  contains more information about <b><a href={node.frontmatter.url}>{node.frontmatter.id}</a></b>.
                </p>
              </Message.Content>
            </Message>
          :
          null
        }

        <Header as="h2" dividing>
          <Header.Content>
            {mcs.nodes.length} Misconceptions Expressible in this Notional Machine
          </Header.Content>
        </Header>

        <p>
          A notional machine focuses on a subset of the syntax and semantics of a programming language.
          The following misconceptions are related to the language features
          expressible in this notional machine.
        </p>

        <List selection verticalAlign="middle">
          {mcs.nodes.map((mcNode) => (
            <List.Item as={Link} to={mcNode.fields.slug} key={mcNode.fields.slug}>
              <img
                className="ui image"
                alt="Misconception"
                width="20"
                src={logo}
              />
              <List.Content>
                <List.Header>
                  {/* color this like a link, without producing a nested a element */}
                  <span style={{ color: "#4183c4" }}>{mcNode.fields.name}</span>
                </List.Header>
                {mcNode.frontmatter.shortDescription}
              </List.Content>
            </List.Item>
          ))}
        </List>

      </Container>
    </Layout>
  )
}

export default NotionalMachinePageTemplate

export const query = graphql`
  query NM($slug: String, $name: String) {
    nm: mdx(slug: {eq: $slug}) {
      slug
      body
      frontmatter {
        id
        name
        shortDescription
        url
      }
    }
    mcs: allMdx(
      filter: {
        frontmatter: {
          expressibleInNms: {
            elemMatch: { id: { eq: $name } }
          }
        }
      }, 
      sort: {
        fields: fields___name
      }
    ) {
      nodes {
        fields {
          slug
          name
        }
        frontmatter {
          shortDescription
          specificToNm
          expressibleInNms {
            id
            how
          }
        }
      }
    }
  }
`
